@import "../../../data/styles.css";

.homepage-achievement {
	width: 90%;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
}

.homepage-achievement:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.homepage-achievement-content {
	padding-top: 1em;
	padding-left: 30px;
}

.homepage-achievement-date {
	opacity: 1;
	color: var(--tertiary-color);
	font-size: 12px;
	margin-bottom: 1em;
}

.homepage-achievement-title {
	opacity: 1;
	color: var(--primary-color);
	font-size: 16px;
	margin-bottom: 1em;
	font-weight: 700;
}

.homepage-achievement-description {
	opacity: 1;
	color: var(--secondary-color);
	font-size: 12px;
	line-height: 1.5;
}

.homepage-achievement-link {
	opacity: 1;
	padding-top: 20px;
	font-size: 15px;
	font-weight: 700;
}

.homepage-achievement-link a {
	color: var(--link-color);
	text-decoration: none;
}

@media (max-width: 600px) {
	.homepage-achievement {
		width: 100%;
	}

	.homepage-achievement-content {
		padding: 2em;
		padding-left: 3em;
	}
}